.wrapChild {
  width: 1190px;
  margin: 0 auto;
  position: relative;
}
.filterTypes {
  width: 100%;
  line-height: 37px;
  height: 37px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 33px auto;
}

.dateTypes {
  min-width: 60px;
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #c7c7d1;
  line-height: 26px;
}

.TypesBox {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
}
.TypesBox::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.totalTypes {
  font-size: 18px;
  font-weight: 600;
  color: #7b859c;
}
.types {
  height: 37px;
  padding: 0 15px;
  color: #7b859c;
  white-space: nowrap;
  cursor: pointer;
  margin: 0 0 0 8px;
}
.types:hover {
  color: #719af8;
}
.selectType {
  background: #f3f6fb;
  border-radius: 18px;
}
/* discover */
.discover {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 40px;
  /* height: 988px; */
}
.filterContent {
  width: 100%;
  height: 26px;
  font-size: 18px;
  font-weight: 500;
  color: #c7c7d1;
  line-height: 26px;
  margin: 40px auto 21px;
}
.discoverWrap {
  width: 1190px;
  margin: 0 auto;
}
.discoverMore {
  height: 100px;
  width: 100%;
  line-height: 100px;
}
.discoverMore span {
  float: right;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7b859c;
}
.discoverList {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.discoverItem {
  /* height: 221px; */
  width: 275px;
  cursor: pointer;
}
.discoverItem:nth-of-type(n + 5) {
  margin-top: 53px;
}
.discoverImg {
  width: 100%;
  height: 146px;
  border-radius: 15px;
}
.discoverTitle {
  max-height: 66px;
  font-size: 23px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #808080;
  line-height: 33px;
  margin: 13px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.discoverLike {
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7b859c;
  line-height: 30px;
  vertical-align: middle;
}
.iconLike {
  height: 26px;
  width: 30px;
  display: inline-block;
  margin-top: -5px;
  margin-right: 5px;
}

.getMore {
  margin: 0 auto;
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: #f5f5f9;
  font-size: 18px;
  font-weight: 500;
  color: #788298;
  border-radius: 40px;
  margin-top: 53px;
  cursor: pointer;
}

.listCount {
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #c7c7d1;
  border-radius: 40px;
  margin: 14px auto 38px;
}
.del {
  text-align: center;
  line-height: 36px;
  display: inline-block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  color: #c7c7d1;
  background: #fff;
  margin-left: 11px;
}
.selectT {
  padding: 1px 2px 1px 11px;
}
