/*
 * @Author: 大神小D
 * @Date: 2021-08-05 15:00:04
 * @LastEditTime: 2021-09-27 15:30:25
 * @LastEditors: 大神小D
 * @Description: 
 * @FilePath: \jiaozhuang\src\views\index\index.module.css
 * 
 */
.wrapChild {
  width: 100%;
  position: relative;
}
.leftSwiper :global(.swiper-pagination-bullet-active) {
  width: 15px;
  border-radius: 6px;
  background: linear-gradient(90deg, #577eca 0%, #24509a 100%);
}

.indexTopBg {
  display: block;
  width: 100%;
  min-height: 400px;
}
.caseBox {
  width: 100%;
  height: 1180px;
  background: url(../../images/case-bg.png) top center / 1920px 622px no-repeat
    #f3f6fb;
  overflow: hidden;
}

.caseList {
  height: auto;
  width: 1190px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 225px auto 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
}
@media screen and (max-width: 1210px) {
  .indexTopBg,
  .caseBox {
    width: 1210px;
  }
}
.case {
  width: 218px;
  height: 395px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.case:nth-of-type(3n-1) {
  margin: 0 214px;
}

/* began 4 */
.case:nth-of-type(6n-2) {
  margin: 70px 0;
}
/* began 5 */
.case:nth-of-type(6n-2) {
  margin: 70px 0;
}
/* began 6 */
.case:nth-of-type(6n-2) {
  margin: 70px 0;
}
.caseIcon {
  width: 218px;
  height: 218px;
}
.caseTitle {
  width: 100%;
  height: 42px;
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #7b859c;
  line-height: 42px;
  text-align: center;
}
.caseDesc {
  width: 100%;
  height: 64px;
  font-size: 16px;

  font-weight: 400;
  color: #c7c7d1;
  line-height: 32px;
  -webkit-background-clip: text;
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
}
/* 精选 */
.concentration {
  height: 988px;
  width: 100%;
  background: #fff;
}
.concentrationCtn {
  width: 1190px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leftSwiper {
  height: 100%;
  width: 503px;
}

.tag,
.discoverTag {
  color: #fff;
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 75px;
  width: 229px;
  height: 75px;
  border-radius: 0 0 35px 35px;
  background: linear-gradient(90deg, #24509a 0%, #577eca 100%);
}

.swiper {
  height: 750px;
  width: 100%;
  margin-top: 80px;
}
.swiperWrap {
  height: 100%;
  width: 100%;
}
.swiperItem {
  height: 100%;
  width: 100% !important;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
}

.swiperImg {
  display: block;
  height: 457px;
  width: 503px;
}
.articleCtn {
  height: 200px;
  width: 100%;
  padding: 0 55px;
  border-radius: 0 0 60px 60px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}

.artTitle {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #25519a;
  line-height: 33px;
  margin: 17px 0;
  border: 0 0 35px 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.artCtn {
  height: 68px;
  font-size: 20px;
  font-weight: 400;
  color: #7b859c;
  line-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.rightList {
  height: 100%;
  width: 588px;
  padding-top: 148px;
  box-sizing: border-box;
}
.activeList {
  height: 100%;
  width: 100%;
  margin: 0;
}
.actives {
  /* height: 236px; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.more {
  float: right;
  font-size: 18px;
  font-weight: 400;
  color: #7b859c;
  cursor: pointer;
}
.activeTop {
  height: 34px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actives:nth-of-type(2) {
  border-bottom: 1px solid #979797;
  border-top: 1px solid #979797;
}
.actives:last-child {
  display: block;
  text-align: right;
  font-size: 18px;
  color: #7b859c;
  cursor: pointer;
  padding-top: 20px;
  box-sizing: border-box;
}
.actives:nth-of-type(n + 2) > .activeTop {
  margin-top: 40px;
}
.activesType {
  height: 100%;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 34px;
  color: #7b859c;
  background: #f3f6fb;
  border-radius: 60px;
}
.activesName {
  font-size: 24px;
  font-weight: 400;
  color: #808080;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.activesTime {
  font-size: 16px;
  font-weight: 400;
  color: #c7c7d1;
}
.activesContent {
  width: 100%;
  margin: 30px auto;
  height: 90px;
  font-size: 16px;
  text-align: left;

  font-weight: 400;
  color: #808080;
  line-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

/* discover */
.discover {
  width: 100%;
  max-height: 988px;
  background: #f3f6fb;
}
.discoverWrap {
  width: 1190px;
  margin: 0 auto;
}
.discoverMore {
  height: 100px;
  width: 100%;
  line-height: 100px;
}
.discoverMore span {
  float: right;
  font-size: 18px;

  font-weight: 400;
  color: #7b859c;
}
.discoverList {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: flex-start;
  margin: 0;
  padding: 0 0 234px 0;
}
.flexStart {
  /* justify-content: flex-start; */
}
.discoverItem {
  /* height: 221px; */
  width: 275px;
}
.marginRight {
  /* margin-right: 100px; */
}
.discoverItem:nth-of-type(4n - 2) {
  margin: 0 15px 0 30px;
}
.discoverItem:nth-of-type(4n - 1) {
  margin: 0 30px 0 15px;
}
.discoverItem:nth-of-type(n + 5) {
  margin-top: 53px;
}
.discoverImg {
  width: 100%;
  height: 146px;
  border-radius: 15px;
  cursor: pointer;
}
.discoverTitle {
  max-height: 66px;
  font-size: 23px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #808080;
  line-height: 33px;
  margin: 13px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.discoverLike {
  height: 30px;
  font-size: 22px;

  font-weight: 400;
  color: #7b859c;
  line-height: 30px;
  vertical-align: middle;
}
.iconLike {
  vertical-align: middle;
  height: 26px;
  width: 30px;
  margin: -3px 10px 0 0;
  cursor: pointer;
}
