.wrapChild {
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  position: relative;
  /* background: url('../../images/toplistbg.png') center top 100px no-repeat; */
  background-size: 100% 475px;
}
.inner {
  width: 1190px;
  margin: 0 auto;
  overflow: hidden;
}
.topList {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 85px auto;
}
.topItem {
  height: 100%;
}
.topImg {
  width: 100%;
  /* height: 139px; */
  background: #ffffff;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  border-radius: 50px;
}
.typeTitle {
  text-align: center;
  font-size: 22px;
  color: #7b859c;
  margin-top: 15px;
}
.topLink {
  display: block;
  height: 100%;
  width: 140px;
}
.selectTopType {
  width: 175px;
  margin-top: -20px;
}
.selectTopType p {
  color: #1951a0;
  font-size: 27px;
  font-weight: 600;
}
