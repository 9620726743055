.wrapChild {
  width: 100%;
  min-height: calc(100vh - 100px);
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 120px 0 20px 0;
  box-sizing: content-box;
  background: url('../../images/register-bg.png') center 100px / cover no-repeat;
}
.inputBox {
  width: 549px;
  background: #ffffff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 27px;
  overflow: hidden;
}
.tabBox {
  width: 366px;
  height: 50px;
  margin: 49px auto 33px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.registerType {
  display: block;
  width: 40%;
  text-align: center;
  border-bottom: 3px solid #7b859c;
  font-size: 22px;
  font-weight: 500;
  color: #7b859c;
  padding: 10px 0;
}
.selectCityTitle {
  width: 366px;
  margin: 0 auto 10px;
  height: 23px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 23px;
}
.citySelectBox {
  width: 45%;
  height: 100%;
}
.selectProvince,
.selectArea {
  width: 165px;
  height: 46px;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
}
.inputCity {
  width: 370px;
  height: 46px;
  margin: 0 auto 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.inputArea {
  width: 366px;
  height: 46px;
  margin: 0 auto 30px;
  border-radius: 5px;
  border: 1px solid #808080;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.iptIcon {
  width: 23px;
  height: 31px;
  margin: 7.5px 7.5px 7.5px 20px;
}
.telIpt {
  display: block;
  height: 31px;
  width: 100%;
  outline: none;
  border: none;
  padding: 0 16px;
}
.yzmIpt {
  display: block;
  height: 31px;
  width: 155px;
  outline: none;
  border: none;
  padding-right: 10px;
  border-right: 1px solid #7b859c;
  margin-right: 20px;
}
.warning {
  height: 25px;
  font-size: 14px;
  color: #f1403c;
  line-height: 23px;
  text-align: left;
  width: 250px;
  position: absolute;
  top: 50px;
  margin: 0;
}
.codeWarning {
  font-size: 14px;
  color: #f1403c;
  position: absolute;
  left: 58px;
  top: 20px;
  background: #fff;
  z-index: 3;
}
.getYzm {
  height: 100%;
  outline: none;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 400;
  color: #7b859c;
  cursor: pointer;
  width: 200px;
  outline: none;
  border: none;
}
.doLogin {
  cursor: pointer;
  display: block;
  width: 366px;
  height: 55px;
  background: linear-gradient(90deg, #24509a 0%, #577eca 100%);
  border-radius: 27px;
  text-align: center;
  line-height: 55px;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  outline: none;
}

.toRegister {
  background: #f3f6fb;
  text-align: center;
  width: 100%;
  height: 64px;
  line-height: 64px;
  margin-top: 31px;
  border-radius: 0px 0px 27px 27px;
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
}
.toRegisterTitle {
  color: #1951a0;
  font-weight: 900;
  border-bottom: 1px solid #1951a0;
  padding: 3px 0 2px 0;
  cursor: pointer;
}
.selected {
  color: #1951a0;
  border-bottom: 3px solid #1951a0;
  font-weight: 500;
}

@media screen and (max-height: 752px) {
  .inputBox {
    /* width: 500px; */
  }
  .tabBox {
    margin: 30px auto 25px;
  }
  .inputCity {
    margin: 0 auto 20px;
  }
  .otherTitle {
    margin: 20px 98px 16px 98px;
  }
  .toRegister {
    line-height: 55px;
    height: 55px;
    margin-top: 15px;
  }
  .inputArea {
    margin-bottom: 20px;
  }
}
@media screen and (min-height: 752px) {
  .inputBox {
    top: 20%;
  }
}

.popSelect {
  width: 100vw;
  height: calc(100vh - 100px);
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 33;
}
.popSelectBox {
  width: 634px;
  /* height: 547px; */
  height: 428px;
  background: #ffffff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 27px;
  overflow: hidden;
}
.popTopBox {
  width: 634px;
  height: 82px;
  background: #f3f6fb;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popSearchBox {
  width: 365px;
  height: 46px;
  background: #ffffff;
  border-radius: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 100px;
  padding: 0 10px 0 18px;
}
.searchIpt {
  flex: 1;
  height: 100%;
  border-radius: 27px;
  padding-right: 10px;
}
.popList,
.searchHistory {
  width: 60%;
  height: 80%;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 500;
  color: #808080;
  padding: 30px 10px;
  overflow-y: scroll;
}
.popSearchIcon {
  cursor: pointer;
}
.popItem {
  margin: 15px 0;
  cursor: pointer;
}

.popClose {
  width: 46px;
  height: 46px;
  background: #ffffff;
  color: #c7c7d1;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
  font-size: 35px;
  cursor: pointer;
}
.selectUnit {
  width: 100px;
  text-align: center;
  color: #24509a;
  cursor: pointer;
  border-left: 1px solid #333;
}
