.wrapChild {
  width: 1190px;
  margin: 0 auto;
  position: relative;
}
ul {
  margin: 0;
}
.secondNav {
  height: 55px;
  width: 1190px;
  margin: 0 auto;
  padding-top: 214px;
  border-bottom: 3px solid #1951a0;
  box-sizing: content-box;
}
.secondNav a {
  display: block;
}
.navList {
  display: block;
  width: 156px;
  height: 100%;
  float: left;
  text-align: center;
  line-height: 55px;
  color: #7b859c;
  font-size: 20px;
  cursor: pointer;
}
.selectTab {
  color: #fff;
  border-radius: 30px 30px 0 0;
  background: linear-gradient(90deg, #24509a 0%, #577eca 100%);
}
