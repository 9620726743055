.wrapChild {
  width: 1190px;
  margin: 114px auto 0;
  /* height: 599px; */
  position: relative;
  /* background: #f3f6fb; */
}

/* custom css */
.topSwiper {
  width: 915px;
  height: 458px;
  position: relative;
  border-radius: 18px;
}
.topSwiperWarp {
  width: 915px;
  height: 100%;
  /* margin: 0 auto; */
}
.topSwiperSlide {
  width: 100%;
  height: 100%;
}
:global(.swiper-button-next),
:global(.swiper-button-prev) {
  width: 73px;
  height: 73px;
  border-radius: 50%;
  background: #ffffff;
  color: #333333;
  font-weight: 900;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  top: 210px;
}
:global(.swiper-button-next::after),
:global(.swiper-button-prev::after) {
  font-size: 28px;
}

/* 下面额 */
.bottomSwiper {
  width: 925px;
  height: 104px;
  margin-top: 27px;
  box-sizing: border-box;
  padding: 10px 0;
}

.bottomSwiper :global .swiper-slide {
  width: 25%;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.bottomSwiperWrap {
  height: 100%;
  width: 100%;
}
.bottomSwiperSlide {
  width: 137px !important;
  height: 82px;
  background: #333333;
  border-radius: 18px;
  overflow: hidden;
  box-sizing: border-box;
}
.swiperImg,
.bottomImg {
  /* width: 100%; */
  height: 100%;
}
.blurCtn {
  filter: blur(5px);
}
:global .swiper-slide-thumb-active {
  height: 99px !important;
  width: 165px;
  margin-top: -8.5px;
}
:global .swiper-slide-thumb-active > .bottomImg {
  filter: blur(0px);
}
.swiper-slide-thumb-active {
  width: 1000px;
}
.bottomText {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background: rgba(136, 136, 136, 0.1);
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  padding: 20px;
}
.vr {
  width: 170px;
  height: 170px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -96px 0 0 -96px;
  z-index: 99;
  border-radius: 50%;
  animation: flicker 2s ease-in-out infinite;
  cursor: pointer;
}

@keyframes flicker {
  0% {
    transform: scale(0.7);
    opacity: 0.9;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0.9;
  }
}
.list {
  /* height: 100vh; */
  width: 100%;
  margin: 200px auto 100px;
}
.item {
  height: 165px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 55px;
  cursor: pointer;
}
.itemBg {
  width: 311px;
  height: 165px;
  border-radius: 20px;
  flex-shrink: 0;
}
.rightCtn {
  width: 830px;
  height: 100%;
  margin-left: 40px;
}
.itemTitle {
  height: 30%;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  color: #808080;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.itemCtn {
  width: 100%;
  height: 70%;
  font-size: 16px;
  font-weight: 400;
  color: #c7c7d1;
  line-height: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.swiperTitle {
  width: 201px;
  height: 55px;
  background: linear-gradient(90deg, #24509a 0%, #577eca 100%);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1),
    0px -2px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 50%;
  margin-left: -100.5px;
  top: 0;
  z-index: 2;
  color: #fff;
  border-radius: 0 0 20px 20px;
  text-align: center;
  line-height: 55px;
  font-size: 18px;
}
