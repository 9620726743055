.wrap {
  width: 100%;
  padding-top: 230px;
  margin: 0 auto;
}
.inner {
  width: 1190px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.title {
  width: 100%;
  height: 23px;
  font-size: 16px;
  font-weight: 400;
  color: #c7c7d1;
  line-height: 23px;
  margin: 58px auto 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t:first-child {
  width: 700px;
}
.t:nth-child(2) {
  width: 200px;
}
.t:nth-child(3) {
  width: 100px;
}
.list {
  width: 100%;
  height: calc(100% - 43px);
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 50px;
}
.item {
  width: 100%;
  height: 73px;
  margin: 10px auto;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
}

.itemLeft {
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 27px;
}
.itemMiddle {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.itemRight {
  width: 100px;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
}
.item:nth-of-type(odd) {
  background: #f3f6fb;
}
.downloadOk,
.waitDownload,
.downloading {
  height: 27px;
  line-height: 27px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #7b859c;
  padding: 0 0 0 45px;
}
.downloadOk {
  background: url('../../images/download-ok.png') left center / contain
    no-repeat;
}
.waitDownload {
  cursor: pointer;
  background: url('../../images/wait-down-load.png') left center / contain
    no-repeat;
}
.downloading {
  color: #1951a0;
  padding: 0 0 0 80px;
  background: url('../../images/downloading.png') left center / 70px 10px
    no-repeat;
}

.getMore {
  margin: 0 auto;
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: #f5f5f9;
  font-size: 18px;
  font-weight: 500;
  color: #788298;
  border-radius: 40px;
  margin-top: 53px;
  cursor: pointer;
}

.listCount {
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #c7c7d1;
  border-radius: 40px;
  margin: 14px auto 38px;
}
