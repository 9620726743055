.wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}

.wrap:hover {
  box-shadow: 0 0 7px 0 rgb(174, 196, 255);
}
.placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: #808080;
  background: #fff;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 20px;
}
.wrap::after {
  position: absolute;
  right: 10px;
  top: 13px;
  z-index: 4;
  content: '▼';
  color: #c7c7d1;
}
.selectBox {
  width: 100%;
  position: absolute;
  left: 0;
  top: 66px;
  background: #fff;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 3;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.selectBox .options {
  height: 46px;
  background: #fff;
  text-align: center;
  line-height: 46px;
  color: #808080;
}
.selectBox .options:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #000;
}
