.wrap {
  width: 100%;
  min-height: 50vh;
  margin: 0 auto;
}
.inner {
  width: 1190px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.leftPart {
  width: 122px;
  /* height: 500px; */
  /* position: fixed;
  top: 402px; */
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 30px;
}
.leftPart::-webkit-scrollbar {
  width: 0;
}
.partItem {
  width: 100%;
  height: 46px;
  display: block;
  margin: 0 auto 10px;
  text-align: center;
  line-height: 46px;
  color: #808080;
  cursor: pointer;
}
.partItem a {
  color: #808080;
}
.select {
  display: block;
  width: 100%;
  height: 46px;
  background: #f7931e;
  color: #fff !important;
  border-radius: 25px 0 0 25px;
}
.getMore {
  margin: 0 auto;
  width: 183px;
  line-height: 255px;
  text-align: center;
  /* background: #f5f5f9; */
  font-size: 18px;
  font-weight: 500;
  color: #788298;
  /* border-radius: 40px; */
  cursor: pointer;
}

.listCount {
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #c7c7d1;
  border-radius: 40px;
  margin: 14px auto 38px;
}
