.wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}

.getMore {
  margin: 0 auto;
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: #f5f5f9;
  font-size: 18px;
  font-weight: 500;
  color: #788298;
  border-radius: 40px;
  margin-top: 53px;
  cursor: pointer;
}

.listCount {
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #c7c7d1;
  border-radius: 40px;
  margin: 14px auto 38px;
}
