.wrap {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
}

.list {
  width: 1011px;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin-left: 60px;
}
.item {
  width: 210px;
  height: 280px;
  /* background: linear-gradient(#f9f9f9, 60%, #c8c4c0); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
  margin: 25px 0 25px 38px;
  cursor: pointer;
}
.productImg {
  /* height: 100px;
  width: 90%; */
  width: 210px;
  height: 210px;
  border-radius: 20px;
}
.productName {
  width: 100%;
  height: 25px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
}
.productCode {
  width: 100%;
  height: 23px;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.itemLeft {
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 27px;
}
.itemMiddle {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.itemRight {
  width: 100px;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
}
.defaultPage {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #788298;
  line-height: 300px;
}
