.wrapChild {
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 150px);
  padding-top: 100px;
  box-sizing: content-box;
  background: url('../../images/guide-bg.png') center / 100% 100% no-repeat;
}
.changeBg {
  background: #fff;
}
.inner {
  width: 1190px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.topInfo {
  width: 100%;
  height: 183px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 91px auto 0;
}
.avatar {
  height: 100%;
  width: 183px;
  border-radius: 50%;
}
.nickname {
  width: 250px;
  height: 51px;
  font-size: 37px;
  font-weight: 500;
  color: #1951a0;
  line-height: 51px;
  overflow: hidden;
}
.userId {
  width: 250px;
  font-size: 18px;
  font-weight: 400;
  color: #7b859c;
}
.editInfo {
  width: 183px;
  line-height: 55px;
  height: 55px;
  background: #ffffff;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  font-weight: 500;
  color: #c7c7d1;
  margin-left: 200px;
  cursor: pointer;
}
.exit {
  width: 120px;
  height: 26px;
  font-size: 18px;
  font-weight: 400;
  color: #7b859c;
  line-height: 26px;
  float: right;
  vertical-align: middle;
  margin-top: 26px;
  cursor: pointer;
}
.exit::after {
  margin-left: 10px;
  vertical-align: middle;
  content: '';
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url('../../images/exit.png') center / contain no-repeat;
}
.myThing {
  width: 100%;
  margin: 116px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.thingItem {
  width: 329px;
  height: 92px;
  line-height: 85px;
  text-align: center;
  background: #ffffff;
  border-radius: 50px;
  font-size: 22px;
  font-weight: 500;
  color: #1951a0;
  cursor: pointer;
}
.myCollect {
  background: url('../../images/my-collect.png') center / contain no-repeat;
}

.myDownload {
  background: url('../../images/my-downoad.png') center / contain no-repeat;
}
