.wrapChild {
  width: 100%;
  margin: 100px auto 0;
  position: relative;
  z-index: 5;
  background: #fff;
}
p {
  margin: 0;
}
.inner {
  width: 1190px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.fixedTop {
  position: fixed;
  left: 50%;
  top: 100px;
  width: 1190px;
  margin-left: -595px;
  background: #fff;
  padding-top: 58px;
}
.bread {
  width: 100%;
  padding-bottom: 60px;
}
.secondNav {
  height: 55px;
  width: 1190px;
  border-bottom: 3px solid #1951a0;
}
.navList {
  display: block;
  width: 156px;
  height: 100%;
  float: left;
  text-align: center;
  line-height: 55px;
  color: #7b859c;
  font-size: 20px;
  cursor: pointer;
}
.selectTab {
  color: #fff;
  border-radius: 30px 30px 0 0;
  background: linear-gradient(90deg, #24509a 0%, #577eca 100%);
}
