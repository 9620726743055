.wrapChild {
  width: 100%;
  margin: 100px auto 0;
  position: relative;
  background: #fff;
}
.inner {
  width: 1190px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 51px 0;
}
.partLeft {
  width: 600px;
}
.detailTitle {
  width: 100%;
  font-weight: 600;
  line-height: 30px;
  margin: 100px 0 30px 0;
  font-size: 22px;
  color: #333333;
}
.detailTime {
  width: 100%;
  height: 23px;
  font-size: 16px;
  color: #8590a6;
  line-height: 23px;
  margin: 20px 0;
}
.contentBox {
  width: 100%;
  padding: 40px 0;
}
.contentBox img {
  display: block;
  width: 100% !important;
}
.desc {
  font-size: 18px;
  font-weight: 300;
  color: #808080;
}
.params {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px 0;
  margin: 40px 0;
}
.paramsCode {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.paramName {
  text-align: center;
  min-width: 64px;
  height: 27px;
  line-height: 27px;
  background: #f3f6fb;
  border-radius: 14px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  color: #8590a6;
  margin-right: 20px;
}
.paramVal {
  color: #808080;
}
.leftFile {
  display: block;
  width: 100%;
  margin-top: 56px;
}
.btns {
  height: 64px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.btnCollectN {
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  outline: none;
  border: 0;
  width: 220px;
  height: 64px;
  background: url(../../images/no-collect.png) center/contain no-repeat;
  border-radius: 32px;
  margin-right: 57px;
}
.btnCollectY {
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  outline: none;
  border: 0;
  width: 220px;
  height: 64px;
  background: url(../../images/yes-collect.png) center/contain no-repeat;
  border-radius: 32px;
  margin-right: 57px;
}
.btnShare {
  cursor: pointer;
  outline: none;
  border: 0;
  width: 64px;
  height: 64px;
  background: url('../../images/share.png') center/contain no-repeat;
}
.partRight {
  flex: 1;
  padding-left: 100px;
}
.toTop {
  /* position: fixed;
  right: 266px;
  bottom: 66px; */
  width: 77px;
  height: 77px;
  z-index: 10;
  cursor: pointer;
  cursor: pointer;
  color: red !important;
  background: red !important;
}
.bottomList {
  width: 1190px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 100px;
}
.rT {
  width: 100%;
  height: 54px;
  line-height: 54px;
  font-size: 22px;
  /* font-weight: 600; */
  color: #7b859c;
  border-top: 1px solid #c7c7d1;
  border-bottom: 1px solid #c7c7d1;
  margin-bottom: 61px;
}
.listLeft {
  width: 210px;
}
.listRight {
  width: 825px;
}
.l.r {
  width: 100%;
}
.l {
  margin-bottom: 36px;
  cursor: pointer;
}

.lImg {
  width: 210px;
  height: 210px;
  border-radius: 20px;
}
.lTitle {
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  color: #7b859c;
  line-height: 40px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rImg {
  width: 825px;
  height: 549px;
  margin-top: 33px;
}
.r {
  font-size: 18px;
  font-weight: 300;
  color: #8590a6;
  line-height: 29px;
}
