.wrap {
  width: 366px;
  height: 329px;
  background: #f9f9f9;
  border-radius: 27px;
  box-sizing: border-box;
  padding: 30px 41px 37px 41px;
  margin: 43px auto;
}
.listBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.t {
  width: 100%;
  height: 26px;
  font-size: 18px;
  font-weight: 500;
  color: #3a3a3a;
  line-height: 26px;
}

.item {
  width: 33.33%;
  box-sizing: border-box;
  font-size: 16px;
  color: #808080;
  margin: 0 auto;
  cursor: pointer;
}
.funIcon {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
}
.funName {
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 16px;
  color: #7b859c;
  line-height: 40px;
  overflow: hidden;
}
