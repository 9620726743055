.wrapChild {
  width: 1190px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-size: 25px;
}
.repair {
  display: block;
  margin: 30px auto;
  width: 200px;
}
.frame {
  width: 100%;
  height: 60vh;
  vertical-align: bottom;
  margin: 0 auto 40px;
}
