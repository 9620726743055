@import-normalize;
/**
 * Add the correct display in IE 9-.
 */
body,
html,
ul {
  margin: 0;
  padding: 0;
  width: 100%;
  /* overflow-x: hidden;
  overflow-y: scroll; */
}
::-webkit-scrollbar {
  width: 3px;
  background: #eaeaea;
}
::-webkit-scrollbar-thumb {
  background: RGBA(78, 117, 193, 1);
}
#root {
  width: 100%;
}
audio,
video {
  display: inline-block;
}
p,
h3 {
  margin: 0;
  padding: 0;
}
input {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
/**
  * Remove the border on images inside links in IE 10-.
  */

img {
  border-style: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  width: 10px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: AppLogoSpin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes AppLogoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
