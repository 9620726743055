.wrapChild {
  width: 100%;
  height: 967px;
  position: relative;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  background: url('../../images/register-bg.png') center 100px / cover no-repeat;
}
.thirdIcon {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  height: 100px;
}
.link {
  height: 50px;
  width: 50px;
  margin: 0 47px;
}
.wechat,
.qq,
.alipay {
  height: 100px;
  width: 100px;
}
.inputBox {
  width: 549px;
  height: 540px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -274.5px;
  margin-top: -270px;
}
.inputTitle {
  width: 231px;
  height: 23px;
  font-size: 16px;
  font-weight: 500;
  color: #757575;
  text-align: center;
  width: 100%;
  margin: 47px 0 28px 0;
}
.inputBottom {
  background: #ffffff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 27px;
  padding: 45px 0;
}
.inputArea {
  width: 366px;
  height: 55px;
  margin: 0 auto 30px;
  border-radius: 5px;
  border: 1px solid #808080;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.iptIcon {
  width: 23px;
  height: 31px;
  margin: 12px 12px 12px 23px;
}
.telIpt {
  display: block;
  height: 31px;
  width: 170px;
  outline: none;
  border: none;
}
.yzmIpt {
  display: block;
  height: 31px;
  width: 155px;
  outline: none;
  border: none;
  padding-right: 10px;
  border-right: 1px solid #7b859c;
  margin-right: 20px;
}
.warning {
  height: 25px;
  font-size: 14px;
  color: #f1403c;
  line-height: 23px;
  text-align: left;
  width: 250px;
  position: absolute;
  margin: 0;
  top: 50px;
}
.getYzm {
  height: 100%;
  outline: none;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 400;
  color: #7b859c;
  cursor: pointer;
}
.doBind {
  cursor: pointer;
  display: block;
  width: 366px;
  height: 55px;
  background: linear-gradient(90deg, #24509a 0%, #577eca 100%);
  border-radius: 27px;
  text-align: center;
  line-height: 55px;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  outline: none;
}
