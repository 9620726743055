.wrap {
  width: 85%;
  max-height: 60vh;
  min-height: 10vh;
  font-size: 16px;
  color: #8590a6;
  overflow-x: hidden;
  overflow-y: scroll;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  left: 10px;
  top: 50px;
  background: #fff;
  border: 1px solid#BEBEBE;
  border-top: none;
  border-radius: 5px 5px 0 0;
}
.searchList {
  width: 100%;
}
.searchList li {
  height: 40px;
  line-height: 30px;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.searchList li:hover {
  color: #719af8;
  background: rgb(236, 235, 235);
}
