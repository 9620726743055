.wrap {
  width: 366px;
  max-height: 458px;
  background: #ffffff;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.04);
  border-radius: 27px;
  margin: 0 auto;
  padding: 33px 41px 60px 41px;
  box-sizing: border-box;
  overflow: hidden;
}
.listBox {
  width: 100%;
  height: 88%;
  /* overflow-y: scroll; */
  overflow: hidden;
}
.t {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #3a3a3a;
  margin: 10px auto;
}

.item {
  padding: 15px 0;
  width: 100%;
  /* height: 84px; */
  box-sizing: border-box;
  border-bottom: 1px solid #d8d8d8;
  font-size: 16px;
  color: #808080;
  line-height: 23px;cursor: pointer;
}
.item:hover{
  color: cornflowerblue;
}
.item span{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
