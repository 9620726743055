.wrapChild {
  width: 1190px;
  height: 80vh;
  /* background: linear-gradient(to right,rgb(196, 170, 170),rgb(85, 86, 94)); */
  text-align: center;
  margin: 0 auto;
  padding-top: 200px;
}
.four{
  font-size: 80px;
}
.toIndex{
  height: 40px;
  width: 130px;
  background: rgba(87, 126, 202, 1);
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0 20px;
  border-radius: 5px;
}