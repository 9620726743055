.wrapChild {
  width: 1190px;
  margin: 0 auto;
  position: relative;
}
.filterTypes {
  width: 100%;
  line-height: 37px;
  height: 37px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 33px auto;
}

.dateTypes {
  min-width: 60px;
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #c7c7d1;
  line-height: 26px;
}

.TypesBox {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
}
.TypesBox::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.totalTypes {
  font-size: 18px;
  font-weight: 600;
  color: #7b859c;
}
.types {
  height: 37px;
  padding: 0 15px;
  color: #7b859c;
  white-space: nowrap;
  cursor: pointer;
  margin: 0 0 0 8px;
}
.types:hover {
  color: #719af8;
}
.selectType {
  background: #f3f6fb;
  border-radius: 18px;
}
/* discover */
.discover {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 40px;
  /* height: 988px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.discoverMore {
  height: 100px;
  width: 100%;
  line-height: 100px;
}
.filterContent {
  width: 100%;
  height: 26px;
  font-size: 18px;
  font-weight: 500;
  color: #c7c7d1;
  line-height: 26px;
  margin: 40px auto 21px;
}
.discoverMore span {
  float: right;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7b859c;
}
.discoverList {
  width: 700px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.discoverItem {
  width: 100%;
  padding: 18px 0;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  border-bottom: 1px solid #d8d8d8;
}

.CtnTime {
  min-width: 22%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #c7c7d1;
  text-align: right;
}

.discoverCtn {
  width: 83%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 18px;
  font-weight: 500;
  color: #808080;
  line-height: 26px;
}
.rightSwiper {
  padding-left: 122px;
  flex: 1;
  height: auto;
}

.swiperWrap {
  width: 366px;
  height: 499px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.05);
  border-radius: 27px;
}
.swiperItem {
  cursor: pointer;
}
:global(.swiper-pagination-fraction) {
  margin-bottom: -100px !important;
}
.swiperImg {
  display: block;
  width: 100%;
  height: 332px;
}
.articleCtn {
  flex: 1;
  padding: 0 36px;
}
.artTitle {
  height: 27px;
  font-size: 20px;
  color: #333333;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 20px auto 13px;
}

.artCtn {
  width: 293px;
  height: 74px;
  font-size: 15px;
  color: #808080;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.getMore {
  margin: 0 auto;
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: #f5f5f9;
  font-size: 18px;
  font-weight: 500;
  color: #788298;
  border-radius: 40px;
  margin-top: 53px;
  cursor: pointer;
}

.listCount {
  width: 183px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #c7c7d1;
  border-radius: 40px;
  margin: 14px auto 38px;
}
.del {
  text-align: center;
  line-height: 36px;
  display: inline-block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  color: #c7c7d1;
  background: #fff;
  margin-left: 11px;
}
.selectT {
  padding: 1px 2px 1px 11px;
  margin-right: 30px;
}
.rightSwiper :global .swiper-pagination-bullet-active {
  width: 15px;
  border-radius: 10px;
}
.readStatus {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: red;
  margin: 0 5px 0 0;
  flex-shrink: 0;
  position: relative;
  bottom: 4px;
}
