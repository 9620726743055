.wrapChild {
  width: 1190px;
  margin: 0 auto;
  position: relative;
}
.wrapChild span {
  display: inline-block;
  font-size: 30px;
  width: 100%;
  text-align: center;
}
