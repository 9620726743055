.wrapChild {
  width: 100%;
  position: relative;
  padding-top: 178px;
  background: url(../../images/guide-bg.png) top center / contain no-repeat
    #f3f6fb;
}

::-webkit-scrollbar {
  /* width: 0; */
}
.typeBox {
  height: 100%;
  width: 1190px;
  margin: 0 auto;

  overflow: hidden;
}
.typeList {
  height: auto;
  width: 1190px;
  display: flex;
  margin: 17px auto;
  padding-bottom: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.typeTitle {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
  font-weight: 500;
  color: #1951a0;
}
@media screen and (max-width: 1210px) {
  .indexTopBg,
  .caseBox {
    width: 1210px;
  }
}
.type {
  min-width: 265px;
  height: 92px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 22px;
  font-weight: 500;
  color: #7b859c;
  margin: 0 30px 17px 0;
}
.links {
  box-sizing: border-box;
  padding: 31px;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: #7b859c;
  align-items: center;
  cursor: pointer;
}
