.wrapChild {
  width: 100%;
  min-height: calc(100vh - 100px - 183px);
  position: relative;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 112px;
  padding-top: 80px;
  box-sizing: content-box;
  background: url('../../images/login-bg.png') center 100px / cover no-repeat;
}
.a {
  width: 549px;
  height: 540px;
}
.inputBox {
  width: 549px;
  /* height: 540px; */
  background: #ffffff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 27px;
}
.inputTitle {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #7b859c;
  text-align: center;
  margin: 41px auto;
}
.inputArea {
  width: 366px;
  height: 55px;
  margin: 0 auto 30px;
  border-radius: 5px;
  border: 1px solid #808080;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  position: relative;
}
.iptIcon {
  width: 23px;
  height: 31px;
  margin: 12px 12px 12px 23px;
}
.telIpt {
  display: block;
  height: 31px;
  width: 170px;
  outline: none;
  border: none;
}
.yzmIpt {
  display: block;
  height: 31px;
  width: 155px;
  outline: none;
  border: none;
  padding-right: 10px;
  border-right: 1px solid #7b859c;
  margin-right: 20px;
}
.warning {
  height: 25px;
  font-size: 14px;
  color: #f1403c;
  line-height: 23px;
  text-align: left;
  width: 250px;
  position: absolute;
  margin: 0;
  top: 50px;
}
.getYzm {
  height: 100%;
  outline: none;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 400;
  color: #7b859c;
  cursor: pointer;
}
.doLogin {
  cursor: pointer;
  display: block;
  width: 366px;
  height: 55px;
  background: linear-gradient(90deg, #24509a 0%, #577eca 100%);
  border-radius: 27px;
  text-align: center;
  line-height: 55px;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  outline: none;
}
.otherTitle {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #808080;
  margin: 35px 98px 25px 98px;
}
.otherLoginType {
  width: 100%;
  box-sizing: border-box;
  padding: 0 98px 0 98px;
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}
.loginIcon {
  display: block;
  height: 33px;
  width: 33px;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #808080;
}
.toRegister {
  background: #f3f6fb;
  text-align: center;
  width: 100%;
  height: 64px;
  line-height: 64px;
  margin-top: 31px;
  border-radius: 0px 0px 27px 27px;
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
}
.toRegisterTitle {
  color: #1951a0;
  font-weight: 900;
  border-bottom: 1px solid #1951a0;
  padding: 3px 0 2px 0;
  cursor: pointer;
}

@media screen and (max-height: 752px) {
  .inputBox {
    width: 500px;
  }
  .inputTitle {
    margin: 20px auto;
  }
  .otherTitle {
    margin: 20px 98px 16px 98px;
  }
  .toRegister {
    line-height: 55px;
    height: 55px;
    margin-top: 15px;
  }
  .inputArea {
    margin-bottom: 20px;
  }
}
@media screen and (min-height: 752px) {
  .inputBox {
    top: 20%;
  }
}
