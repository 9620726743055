.wrap {
  width: 100%;
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  color: #8590a6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wrap a{
  color: #8590A6;
}
.wrap a:hover{
  color: #5078c3;
}