.wrapChild {
  width: 100%;
  margin: 100px auto 0;
  background: #f0f2f5;
  /* background: url('../../images/guide-bg.png') center / 100% 100% no-repeat; */
}
.inner {
  width: 1190px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.titleBox {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 3px solid #1951a0;
  margin: 91px auto 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  color: #1951a0;
}
.avatarBox {
  display: block;
  margin: 54px auto 69px;
  width: 137px;
  height: 137px;
  /* background: #ffffff; */
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.infoAvatar {
  width: 100%;
  height: 100%;
  display: block;
}
.btnUpload{
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.changeInfoBox {
  width: 100%;
  height: 80px;
  font-size: 37px;
  font-weight: 500;
  color: #1951a0;
  line-height: 80px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.changeInfoBox:nth-of-type(2) {
  border: none;
}
.icon {
  width: 30px;
  height: 32px;
}
.ipt {
  display: block;
  padding: 20px 50px;
  height: 100%;
  box-sizing: border-box;
  flex: 1;
  background: none;
}
.btn {
  width: 34px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}
.editInfo {
  width: 183px;
  line-height: 55px;
  height: 55px;
  background: #ffffff;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  font-weight: 500;
  color: #c7c7d1;
  margin-left: 200px;
  cursor: pointer;
}

.version {
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #808080;
  line-height: 30px;
  margin: 90px auto;
}
.v {
  width: 222px;
  height: 26px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  margin-left: 46px;
}
.suggest {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
}

.suggestT {
  width: 89px;
  height: 30px;
  font-size: 22px;
  font-weight: 400;
  color: #808080;
  line-height: 30px;
}
.iptArea {
  width: 78%;
  height: 149px;
  background: #ffffff !important;
  border-radius: 18px;
  border: 1px solid #c7c7d1;
  margin: 0 34px;
  resize: none;
  padding: 20px;
  outline: none;
}
.btnSub {
  border: none;
  background: none;
  height: 23px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 23px;
  cursor: pointer;
}
