.layout {
  width: 100%;
  /* height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll; */
  margin: 0 auto;
}
.wrap {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.navWrap {
  height: 100px;
  width: 100%;
  position: fixed;
  box-shadow: 0 3px 40px 5px #e2e2e2;
  left: 0;
  top: 0;
  z-index: 990;
  background: #fff;
}
.noColor {
  background: none;
}
nav {
  width: 1190px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 60px;
  height: 45px;
  cursor: pointer;
}
.navListBox {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  margin: 0;
  font-size: 20px;
}
.navIcon {
  height: 34px;
  width: 34px;
  vertical-align: middle;
}
.navName {
  vertical-align: middle;
  margin: 0 13px;
}
.inputBox {
  height: 50px;
  width: 400px;
  border-radius: 30px;
  box-sizing: border-box;
  background: #f5f5f5;
  /* overflow: hidden; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border: 1px solid #eeeef2;
  position: relative;
}
.inputArea {
  width: 100%;
  display: block;
  height: 100%;
  border: none;
  outline: 0;
  background: #f5f5f5;
  padding-right: 20px;
}
.iconSearch {
  width: 34px;
  height: 34px;
  cursor: pointer;
}
.listBox a {
  display: inline-block;
}
.listName {
  display: block;
  cursor: pointer;
  filter: grayscale(90%);
}

.userInfo {
  color: rgba(123, 133, 156, 1) !important;
  font-weight: 500;
}

.userInfo:active {
  color: #000;
}

.userAvatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  vertical-align: middle;
}
.userNickname {
  margin-left: 19px;
  vertical-align: middle;
  font-size: 20px;
}
.selected {
  color: #1951a0;
  filter: grayscale(0);
}

@media screen and (max-width: 1000px) {
  .layout {
    width: 1000px;
  }
}

/* footer */
.footer {
  width: 100%;
  height: 183px;
  background: linear-gradient(90deg, #577eca 0%, #24509a 100%);
}
.btmBox {
  width: 1190px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btmLogo {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.middleInfo {
  height: 110px;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.middleTop {
  height: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #979797;
  color: #fff;
  padding-bottom: 11px;
  box-sizing: content-box;
  margin-bottom: 11px;
  font-size: 16px;
}
.tel {
  width: 250px;
}
.componyInfo {
  height: 50%;
  width: 100%;
  color: #fff;
  text-align: left;
  font-size: 15px;
}
.ewmBox {
  width: 110px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}
.ewmBox img {
  width: 100%;
  /* margin-bottom: 8px; */
}
.ewmBox p {
  margin-top: 8px;
}
.padding10 {
  border-radius: 20px;
  padding: 2px;
  background: #fff;
  box-sizing: border-box;
}
.padding20 {
  border-radius: 20px;
  padding: 5px;
  background: #fff;
  box-sizing: border-box;
}
.ga {
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: -4px 4px 0 0;
}
