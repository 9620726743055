.wrapChild {
  width: 100%;
  margin: 100px auto 0;
  position: relative;
  background: #fff;
}
.inner {
  width: 1190px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 51px 0 100px 0;
}
.partLeft {
  width: 732px;
}
.detailTitle {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: #1951a0;
  line-height: 30px;
  margin-top: 55px;
}
.detailTime {
  width: 100%;
  height: 23px;
  font-size: 16px;
  color: #8590a6;
  line-height: 23px;
  margin-top: 51px;
}
.contentBox {
  width: 100%;
  padding: 40px 0;
}
.contentBox img {
  display: block;
  width: 100% !important;
}
.leftFile {
  display: block;
  width: 100%;
  margin-top: 56px;
}
.partRight {
  flex: 1;
}
.toTop {
  /* position: fixed;
  right: 266px;
  bottom: 66px; */
  width: 77px;
  height: 77px;
  z-index: 10;
  cursor: pointer;
  color: red!important;
  background: red!important;
}
